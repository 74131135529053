
import { defineComponent } from "vue";
//import { Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "job-history",
  components: {
    //Datatable,
  },
  data() {
    return {
      showDescription: false,
      loading: false,
      registration_number: "",
      EmploymentList: [],
    };
  },
  async created() {},
  methods: {
    async formSubmit() {
      //form submit
    },
    async getHistory() {
      if(this.registration_number){
        this.loading = true;
      ApiService.get(
        "employment/history?registration_number=" + this.registration_number
      )
        .then((response) => {
          console.log(response);
          this.EmploymentList = response.data.data.trainee_employment;
          this.showDescription = true;
          this.loading = false;
        })
        .catch((response) => {
          console.log(response);
           this.loading = false;
        });
      }else{
        this.loading = false;
        Swal.fire({
          title: "Warning!",
          html: "Please input registration number.",
          icon: "warning",
        });
      }
    },
  },
  setup() {},
});
